.logo-inner,.logo-left-close-btn,.mail-edit,.sprite-icon, .mid-banner-close-btn
{
  background-image: url(../img/common_sprite.png);
  background-repeat: no-repeat;
}

.sprite-icon-download{
  background-position: -4px -42px;
}
.sprite-icon-bt{
  background-position: -23px -4px;
}
.sprite-icon-music{
  background-position: -50px -42px;
}
.sprite-icon-shopping{
  background-position: -72px -42px;
}
.sprite-icon-life{
  background-position: -93px -42px;
}
.sprite-icon-software{
  background-position: -114px -42px;
}
.sprite-icon-education{
  background-position: -128px -4px;
}
.sprite-icon-finance{
  background-position: -4px -64px;
}
.sprite-icon-news{
  background-position: -26px -64px;
}
.sprite-icon-sports{
  background-position: -50px -64px;
}
.sprite-icon-video{
  background-position: -72px -64px;
}
.sprite-icon-community{
  background-position: -93px -64px;
}
.sprite-icon-storage{
  background-position: -115px -64px;
}
.sprite-icon-anime{
  background-position: -116px -87px;
}
.sprite-icon-games{
  background-position: -93px -87px;
}

.mail-edit{
  background-position:-158px -42px;
}