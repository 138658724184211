/***** Common styles *****/

.logo {
  position: relative;
  float: left;
  width: 244px;
  height: 62px;

  .logo-left{
    position: absolute;
    background-color: #f5f5f5;
    z-index: 12;
    top: 74px;
    right: 245px;
    width: auto;
    height: auto;

    .logo-left-close-btn {
      position: absolute;
      display: none;
      width: 24px;
      height: 24px;
      right: 0;
      top: 0;
      margin: 0;
      z-index: 200;
      background-position: -182px -69px;
      &:hover {
        background-position: -158px -69px;
      }
    }
    &:hover .logo-left-close-btn {
      display: block;
    }
  }

  .logo-main{
    display: block;
    width: 100%;
    height: 100%;
  }
  .logo-inner {
    display: block;
    width: 164px;
    height: 38px;
    position: relative;
    top: 12px;
    left: 33px;
    background-position: 0 -2px;
  }
}
