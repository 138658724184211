.weather {
  position: relative;
  font-size: 16px;
  line-height: 16px;
  float: left;
  margin-left: 28px;
  width: 288px;
  height: 80px;
}
.weather-loading {
  margin: 0 auto;
  margin-top: 7px;
  display: block;
  width: 65px;
  height: 65px;
  animation: loading 2s infinite linear;
  -webkit-animation: loading 2s infinite linear;
}
@keyframes loading {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
@-webkit-keyframes loading {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
#J_weather {
  width: 288px;
  height: 80px;
}
.weather a:hover{
  color: #555555;
  text-decoration: none;
}
.calender{
  float: right;
  width: 154px;
  position: absolute;
  right: 0;
  bottom: 8px;
}
.weather-city {
  position: absolute;
  left: 0;
  bottom: 8px;
  margin-left: 10px;
  font-weight: bolder;
}
.city-name{
  float: left;
  cursor: pointer;
  width: 97px;
}
.city-setting {
  background: url(//gtd.alicdn.com/tfscom/TB13FUULpXXXXcDXFXXwu0bFXXX) center no-repeat;
  width: 23px;
  height: 15px;
  display: block;
  float: left;
  margin-left: -8px;
  cursor: pointer;
}
.weather-wrapper {
  display: block;
  width: 288px;
  height: 58px;
}
.weather-condition {
  position: absolute;
  top: 2px;
  left: 0;
  img{
    float: left;
    margin-left: 10px;
    width: 52px;
    height: 52px;
  }
}
.weather-temper {
  float: left;
  margin-left: 8px;
  margin-top: 17px;
  line-height: 35px;
  font-size: 28px;
}
.weather-detail {
  width: 154px;
  position: absolute;
  top: 15px;
  right: 0;
  span{
    display: block;
    line-height: 18px;
  }
}
.weather-desc {
  margin-top: 2px;
}
.weather-desc:hover,
.weather-range:hover,
.weather-temper:hover,
.calender span:hover,
.city-name:hover {
  color: #ff962f;
}
.dialog {
  overflow: hidden;
  border: 1px solid #cbcbcb;
  background-color: #fff;
  font-size: 16px;
  line-height: 16px;
  select{
    width: 102px;
    height: 22px;
    margin-right: 6px;
  }
  .button{
    background-color:#ff962f;
    color: #fff;
    padding: 2px;
    margin-left: 4px;
  }
  .button:hover{
    text-decoration: none;
  }
}
.dialog-head {
  margin-top: 12px;
  margin-left: 10px;
}
.dialog-content {
  margin-top: 6px;
  margin-left: 10px
}

.dialog-btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 11px;
  width: 11px;
  display: block;
  background: url(//gtd.alicdn.com/tfscom/TB1G0oSLpXXXXajXVXXwu0bFXXX) no-repeat;
}