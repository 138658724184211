/***** Middle News & Video Part *****/
#J_middle {
  display: table;
}

.middle {
  position: relative;
  width: 508px;
  height: 404px;
  &.video{
    margin-left: 4px;
  }
  &.news {
    border-bottom: none;
    .mid-content{
      height: 354px;
    }
  }
  .title {
    position: relative;
    height: 49px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e8e8e8;
  }
  .mid-content {
    position: absolute;
    width: 100%;
  }
}

.news-tabs{
  li{
    font-size: 14px;
    line-height: 50px;
    float: left;
    padding: 0 8px;
    cursor: pointer;
  }
  .active{
    background-color: #fff;
    height: 47px;
    border-bottom: 3px solid #ff962f;
  }
}

.news-content {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  height: 70px;
  display: block;

  &:hover{
    text-decoration: none;
  }
  p{
    position: absolute;
    top: 48px;
    left: 14px;
  }
  img{
    width: 81px;
    height: 50px;
    position: absolute;
    left: 418px;
    top: 10px;
  }
  .news-title {
    width: 394px;
    position: absolute;
    top: 10px;
    left: 14px;
    font-size: 16px;
    height: 16px;
    color: #505050;
    &.long-title {
      width: 481px;
    }
  }
  .news-view-num {
    color: #6f6f6f;
    i{
      display: inline-block;
      width: 16px;
      height: 10px;
      margin-right: 5px;
      background: url(../img/sprite.png) no-repeat;
      background-position:-165px -6px;
    }
  }
  .news-time {
    margin-left: 12px;
    color: #ff962f;
  }
}
.refresh-btn{
  display: inline-block;
  width: 19px;
  height: 18px;
  position: absolute;
  right: 12px;
  bottom: 15px;
  cursor: pointer;
  background: url(../img/sprite.png) no-repeat;
  background-position: -165px -19px;
}
.video{
  h3{
    font-size: 18px;
    padding: 16px 0;
    border-left: 3px solid #ff962f;
  }
  a{
    position: relative;
    display: block;
    overflow: hidden;
    margin-right: 10px;
  }
  .mid-content{
    padding: 9px 0 0 9px;
  }
}
.video1-wrapper {
  height: 234px;
  .mid-content {
    height: 166px;
  }
  img{
    width: 115px;
    height: 165px;
  }
  a{
    width: 115px;
    height: 165px;
  }
  .error-page {
    margin-left: -145px;
  }
}

.video2-wrapper {
  .mid-content {
    height: 101px;
  }
  img{
    width:157px;
    height: 118px;
    margin-top: -15px;
  }
  a{
    width:157px;
    height: 100px;
  }
  .error-page {
    margin-left: -145px;
  }
}

.video-title-2{
  border-top: 1px solid #e8e8e8;
}
.video1-content,.video2-content{
  float: left;
  background: url(../img/placeholder.png) no-repeat;
}
.video1-content {
  background-size: 63px;
  background-position: 26px 41px;
}
.video2-content {
  background-size: 57px;
  background-position: 50px 11px;
}
.video-text-wrapper {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
  background: rgba(0,0,0,0.6);
  span{
    display: block;
    margin:0 8px;
  }
}
.video-tag {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.6);
  color: #fff;
  min-width: 21px;
  height: 16px;
  padding: 0 2px;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
}
.video-tag:after{
  content: "";
  position: absolute;
  top: 0;
  right: -3px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 3px solid rgba(0,0,0,0.6);
  border-bottom: 8px solid transparent;
}
.error-page{
  position: absolute;
  top: 50%;
  left: 50%;
  height: 72px;
  width: 316px;
  margin-top: -36px;
  margin-left: -158px;
  text-align: center;
}
.error-page span{
  display: block;
  width: 72px;
  padding: 3px 6px;
  background-color: #ff962f;
  color: #fff;
  border-radius: 10px;
  font-weight: bolder;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 10px;
}
.sprite-icon-video1 {
  background-position: -137px -65px;
}
.sprite-icon-video2 {
  background-position: -137px -87px;
}
