.mail-selector
{
	background-image: url(../img/sprite.png);
	background-repeat: no-repeat;
}
.mod {
	border: 1px solid #e8e8e8;
	margin: 0 0 12px 0;
	float: left;
	background-color: #fff;
}
.main {
	width: 1024px;
	margin: 0 auto;
	position: relative;
	display: table;
}
#J_header {
	height: 80px;
	margin: 12px 0;
	position: relative;
  z-index: 100;
}
/* 俄罗斯版特殊尺寸 */
.logo {
  height: 80px;
  .logo-inner {
    top: 21px;
  }
  .logo-left {
    top: 92px;
  }
}

.header-links{
	float: right;
}
.header-links .header-link {
	float: left;
	width: 222px;
	height: 80px;
	/*line-height: 80px;*/
	margin-left: 10px;
	cursor: pointer;
}
.header-link a {
  display: block;
  width: 222px;
  height: 80px;
}
.header-link img{
	width: 222px;
  height: 80px;
}

#J_searchBanner{
	position: relative;
	width: 1022px;
	height: 106px;
	-moz-box-shadow: rgba(0,0,0,.1)0 3px 5px;
  -webkit-box-shadow: rgba(0,0,0,.1)0 3px 5px;
  box-shadow: rgba(0,0,0,.1)0 3px 5px;
}
.search-bg-left{
  float: left;
  a{
    display: block;
    width: 300px;
    height: 106px;
  }
}
.search-bg-right{
  float: right;
  a{
    display: block;
    width: 300px;
    height: 107px;
  }
}
.search-form {
  position: absolute;
  left: 258px;
  top: 34px;
  background-color: #fff;
}
.search-logo {
  width: 119px;
  height: 49px;
  position: absolute;
  top: 36px;
  left: 123px;
}
.yandex-search-banner .search-logo {
  top: 26px;
  background: url(//gtd.alicdn.com/tfscom/TB17v_hKFXXXXX.XpXXwu0bFXXX) no-repeat;
}
.search-input {
  width: 496px;
  height: 36px;
  font-size: 16px;
  padding-left: 9px;
  /*border: 1px solid #e8e8e8;*/
  /*border-radius: 2px;*/
  position: relative;
}
.yandex-search-banner .search-input{
  -webkit-border-image: -webkit-linear-gradient(right,#ffdb4c 0,#fc0 100%)1;
  -o-border-image: linear-gradient(to left,#ffdb4c 0,#fc0 100%)1;
  border-image: linear-gradient(to left,#ffdb4c 0,#fc0 100%)1;
  border-style: solid;
  height: 34px;
}
.search-btn {
  position: absolute;
  top: 0;
  left: 506px;
  width: 96px;
  height: 38px;
  outline: 0;
  font-size: 18px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.yandex-search-banner .search-btn {
  border: 3px solid #ffdb4c;
  background-color: #ffdb4c;
  color: #000;
}

#J_searchSug{
  position: absolute;
  top: 70px;
  left: 258px;
  min-height: 24px;
  z-index: 20;
  width: 504px;
  border: 1px solid #ccc;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  background-color: #fff;
}
.yandex-search-banner #J_searchSug {
  top: 69px;
  left: 260px;
}
.search-sug li{
  padding-left: 9px;
  height: 24px;
  line-height: 24px;
  color: #222;
  font-size: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.yandex-search-banner .search-sug li{
  cursor: pointer;
  color: #999;
}
.yandex-search-banner .sug-text {
  color: #000;
}
.yandex-search-banner .sug-hover {
  background-color: #ffeba0;
}
.yandex-search-banner .sug-fact{
  color: #999;
  margin-left: 5px;
}
.yandex-search-banner .sug-weather{
  color: #999;
  margin-left: 5px;
}
.yandex-search-banner .sug-nav{
  float: left;
  color: #22c;
}
.yandex-search-banner a:hover{
  text-decoration: none;
}
.yandex-search-banner li:hover .sug-nav{
  color: red;
}
#J_hotSites{
	width: 858px;
	height: 200px;
	float: left;
	border-right: none;
	border-bottom: none;
}
.hotsite-list{
  width: 101%;
  li{
    float: left;
    width: 142px;
    height: 66px;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }
}

#J_tools{
	float: left;
	width: 158px;
	margin-left: 5px;
}
.right-tool {
	width: 100%;
	height: 62px;
	margin-bottom: 5px;
	position: relative;
}
.right-tool a{
	display: block;
	line-height: 62px;
	padding-left: 56px;
  &.double-line {
    line-height: 20px;
    padding-top: 10px;
    height: 52px;
  }
}
.right-tool img{
	width: 26px;
	height: 26px;
	position: absolute;
	left: 20px;
	top: 19px;
}

/***** Mail *****/
.mail-selector{
  width: 160px;
  height: 64px;
  margin-bottom: 5px;
  position: relative;
  text-align: center;
  background-position: -1px -2px;
}
.mail-content-wrapper {
  display: table;
  margin-left: 20px;
  width: 109px;
}
.mail-text {
  display: block;
  cursor: pointer;
  padding-left: 8px;
  line-height: 20px;
  margin-top: 10px;
  text-align: left;
}
.mail-text:hover {
  text-decoration: none;
  color: #555555;
}
.mail-list {
  position: absolute;
  top: 62px;
  left: 0;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-top: none;
}
.mail-item {
  width: 158px;
  height: 62px;
}
.mail-item img{
  width: 158px;
  height: 44px;
  margin: 9px 0;
}
.mail-item.selected {
  width: 158px;
  height: 62px;
  display: block;
  margin-left:-20px;
  img {
    width: 126px;
    height: auto;
    margin: 12px 0 0 25px;
  }
}
.mail-list .mail-item{
  display: block;
  width: 158px;
  height: 62px;
  border-top: 1px dashed #e8e8e8;
}
.mail-edit{
  width: 26px;
  height: 26px;
  margin-top: 17px;
  margin-right: 11px;
  float: left;
  cursor: pointer;
  background-position:-219px -1px;
}
.mail-edit.to-left{
  position: absolute;
  left: 7px;
}

/***** Coolsite Part *****/
#J_coolSites{
	display: table;
	width: 100%;
	margin-bottom: 28px;
}
.coolsite{
	width:508px;
	margin-bottom: 0;
	border-bottom: none;
}
.coolsite.right{
	margin-left: 4px;
}
.coolsite.bottom{
	border-bottom: 1px solid #e8e8e8;
}
.coolsite-title{
	height: 18px;
	font-size: 18px;
  padding: 13px 0;
  border-left: 3px solid #ff962f;
  margin-top: 10px;
}
.coolsite-title.first-line{
	margin-top:0;
}
.coolsite-content{
	overflow: hidden;
	margin: 30px 16px 30px;
	display: block;
}
.coolsite-content li{
	width: 90px;
	height: 32px;
	line-height: 32px;
	float: left;
	margin-right: 5px;
}
.coolsite-content a{
	display: block;
  height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}