/***** Common styles *****/

.hotsite{
  display: block;
  width: 142px;
  position: relative;
  text-align: center;
  height: 66px;

  .hs-img {
    position: absolute;
    top: 0;
    left: 54px;
    width: 34px;
    height: 34px;
    margin-top: 10px;
    z-index: 10;
  }
  .hs-corner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 71px;
    height: 40px;
    z-index: 11;
  }
  span{
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
}
